@import url(../../node_modules/@evertrue/et-components/dist/styles.css);
@import url(../../node_modules/reset-css/reset.css);
@import url(../../node_modules/intl-tel-input/build/css/intlTelInput.css);
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

body {
  background: #fff;
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif;
  color: #454545;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overflow: hidden; }

a {
  color: #29a6c9;
  text-decoration: none; }
  a:hover {
    color: #20839f; }

h1 {
  font-weight: lighter;
  font-size: 32px;
  margin-bottom: 24px; }

h2 {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 24px; }

h3 {
  font-size: 16px;
  margin-bottom: 24px;
  color: #a7a7a7; }

strong {
  font-weight: 500; }

input[type="text"],
input[type="password"] {
  border: solid 1px #e5ebed;
  border-radius: 2px;
  padding: 8px 16px;
  height: 40px;
  font-family: "Roboto", "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif;
  font-size: 14px;
  display: block;
  width: 100%;
  margin-bottom: 8px; }
  input[type="text"]::placeholder,
  input[type="password"]::placeholder {
    color: #a7a7a7; }
  input[type="text"]:focus,
  input[type="password"]:focus {
    outline: none;
    box-shadow: none;
    border-color: #d6dfe3; }
  input[type="text"]:hover,
  input[type="password"]:hover {
    border-color: #d6dfe3; }

button,
a.btn {
  display: block;
  height: 45px;
  width: 100%;
  padding: 12px 16px;
  border-radius: 2px;
  cursor: pointer;
  box-shadow: none;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  background: #f7f7f7;
  border: solid 1px #dfe6e9;
  border-bottom-width: 2px;
  color: #454545;
  text-align: center; }
  button:hover,
  a.btn:hover {
    border-color: #d8d8d8;
    box-shadow: 0 1px 2px #f7f7f7;
    background-color: #f7f7f7; }

.app-controller {
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px; }

.form-status {
  text-align: left;
  padding: 12px 16px;
  border-radius: 2px;
  margin: 12px 0;
  line-height: 18px; }
  .form-status.is-error {
    background-color: #f8d0c8; }
  .form-status.is-success {
    background-color: #e8f7ed; }

.app-layout {
  background: #f7f7f7;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  overflow-y: auto; }
  @media only screen and (max-width: 500px) {
    .app-layout {
      background: #fff; } }
  .app-layout.is-volunteers {
    background: #fff; }
  .app-layout.is-evertrue {
    background: #fff;
    display: flex;
    overflow-y: hidden; }

.app-layout--flex {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%; }
  .app-layout--flex.is-evertrue {
    flex: 2;
    overflow-y: scroll; }

.app-layout--banner {
  display: flex;
  flex-shrink: 0; }
  .app-layout--banner.is-volunteers {
    height: 68px;
    background: #2e3f51; }
    @media only screen and (max-width: 500px) {
      .app-layout--banner.is-volunteers {
        height: 0; } }

.app-layout--wrapper {
  width: 100%;
  max-width: 440px;
  margin: 32px auto;
  flex: 1;
  display: flex;
  flex-direction: column; }
  @media only screen and (max-width: 500px) {
    .app-layout--wrapper {
      margin: auto; } }
  .app-layout--wrapper.is-evertrue, .app-layout--wrapper.is-super, .app-layout--wrapper.is-data {
    max-width: 550px; }

.app-layout--module {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  position: relative; }

.app-layout--header {
  height: 100px;
  border-radius: 2px 2px 0 0;
  background-repeat: no-repeat;
  background-size: 200px;
  background-position: center; }
  .app-layout--header.is-community {
    background-size: 55%;
    display: none; }
  .app-layout--header.is-volunteers {
    height: 93px;
    background-size: 285px; }
    @media only screen and (max-width: 500px) {
      .app-layout--header.is-volunteers {
        margin-top: 20px; } }

.app-layout--body {
  padding: 0px 32px 32px 32px;
  border-radius: 2px;
  position: relative; }

.app-layout--env {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  width: fit-content;
  background-color: #5598ab;
  padding: 12px 16px;
  color: #fff;
  border-radius: 2px;
  font-weight: 500; }

.app-layout--footer {
  display: flex;
  flex-direction: column;
  align-items: center; }

.app-footer {
  text-align: center;
  color: #d8d8d8;
  font-size: 12px;
  line-height: 21px; }
  .app-footer a {
    display: inline-block;
    padding: 0 8px;
    color: #757575; }
  @media only screen and (max-width: 500px) {
    .app-footer {
      font-size: 9px; } }

.app-logos {
  display: flex;
  padding: 8px;
  align-items: center; }

.app-logo img {
  max-height: 40px;
  object-fit: contain;
  width: 100%;
  height: 100%; }
  @media only screen and (max-width: 500px) {
    .app-logo img {
      max-height: 20px; } }

.volunteer-logo {
  padding-top: 5px; }

.app-layout--vol-img {
  width: 100%;
  max-width: 1200px; }

.app-layout--left-banner {
  display: flex;
  justify-content: center;
  flex: 1; }
  @media only screen and (max-width: 500px) {
    .app-layout--left-banner {
      display: none; } }

.app-layout--left-banner__content {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .app-layout--left-banner__content .text-bottom {
    font-size: 15px;
    letter-spacing: 0.1rem;
    color: #fff;
    width: 90%; }
  .app-layout--left-banner__content .text-top {
    letter-spacing: 0.3rem;
    font-weight: 500;
    line-height: 2.8rem;
    font-size: 1.8rem;
    color: #fff;
    width: 90%; }

.app-picker--title {
  font-size: 20px;
  font-weight: 500;
  color: #454545;
  text-align: center;
  margin-top: 24px; }

.app-picker--card {
  cursor: pointer;
  padding: 12px 16px;
  display: block; }
  .app-picker--card:hover {
    background: #f7f7f7; }

.app-picker--avatar {
  display: inline-block;
  vertical-align: middle;
  margin-right: 16px;
  width: 42px; }

.app-picker--details {
  width: calc(100% - 66px);
  display: inline-block;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.app-error--contact-btn.btn {
  background-color: #5598ab;
  color: #fff;
  font-weight: 500;
  border: none;
  padding-top: 14px;
  box-shadow: inset 0 -3px 0 0 #498291; }
  .app-error--contact-btn.btn:hover {
    background-color: #66a2b3; }

.app-error--body.is-evertrue, .app-error--body.is-super, .app-error--body.is-data {
  background-color: #fff;
  border-radius: 16px;
  border: 4px solid black;
  padding: 32px 48px 32px 48px; }

.app-error-try-again--button.is-evertrue, .app-error-try-again--button.is-super, .app-error-try-again--button.is-data {
  width: 50%;
  border-color: #77d3d4;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.forgot-password {
  width: 100%;
  text-align: center; }
  .forgot-password button {
    margin-bottom: 16px; }
  .forgot-password.is-evertrue, .forgot-password.is-super, .forgot-password.is-data {
    background-color: #fff;
    border-radius: 16px;
    border: 4px solid black;
    padding: 32px 48px 32px 48px; }

input.forgot-password--input {
  margin: 16px 0; }

.forgot-password--trouble {
  padding-bottom: 8px; }

.forgot-password--email {
  text-align: left; }

.forgot-password--title {
  text-align: left;
  font-weight: bold;
  font-size: 16px; }

.forgot-password--text {
  padding-bottom: 16px; }

.forgot-password--email-display {
  padding-bottom: 24px; }

.forgot-password--submit, .forgot-password--return {
  width: 65%; }

.forgot-password--password-policy-text {
  margin-top: 16px; }

.login {
  width: 100%;
  margin: 0 auto;
  position: relative;
  text-align: center; }

.login--wrapper {
  background-color: #fff;
  padding: 32px 48px 32px 48px; }

.login--header {
  margin-bottom: 32px; }

.login--linkedin-btn {
  background-color: #0077b5;
  border: none;
  color: #fff; }
  .login--linkedin-btn:hover {
    background-color: #0088cf; }
  .login--linkedin-btn i {
    margin-right: 8px; }

.login--email-btn {
  margin-top: 12px; }

.login-email-color {
  color: white;
  background-color: #30006c;
  border-color: #30006c;
  box-shadow: inset 0 -3px 0 0 #30006c; }
  .login-email-color:hover:not(:disabled) {
    background-color: #3b0086; }
  .login-email-color:focus:not(:disabled) {
    background-color: #30006c;
    border-color: #30006c; }

.login--separator {
  position: relative;
  margin: 12px 0; }
  .login--separator::before {
    content: "";
    height: 1px;
    width: 100%;
    background-color: #e5ebed;
    position: absolute;
    top: 50%;
    right: 0%; }

.login--separator-text {
  background: #fff;
  padding: 0 16px;
  color: #a7a7a7;
  position: relative; }

.login--current-email {
  text-align: left;
  padding: 12px 0 4px 0; }

.login--forgot {
  text-align: center;
  margin: 12px 0;
  display: block; }

.login--change-email {
  text-align: left;
  padding-bottom: 16px; }

.login--content {
  width: 320px;
  margin: 150px auto;
  text-align: center; }

.volunteer-login {
  text-align: center;
  font-size: 16px; }

.volunteer-login--header {
  font-size: 30px;
  line-height: 38px;
  letter-spacing: 0.2px;
  margin-bottom: 20px; }

.volunteer-login--text {
  font-size: 14px;
  margin: 10px 0 30px;
  line-height: normal; }

.volunteer-login--help {
  margin-top: 10px; }

.magic-link-btn--icon {
  margin-right: 4px; }

.magic-link--error {
  color: #eb534b;
  text-align: left;
  padding-top: 4px; }

.magic-link-sent {
  width: 250px;
  text-align: center;
  margin: 0 auto;
  font-size: 16px;
  line-height: 24px; }

.magic-link--mobile-redirect {
  margin: 0 auto;
  position: relative;
  text-align: center; }
  .magic-link--mobile-redirect.is-evertrue, .magic-link--mobile-redirect.is-super, .magic-link--mobile-redirect.is-data {
    background-color: #fff;
    border-radius: 16px;
    border: 4px solid black;
    padding: 32px 48px 32px 48px; }

.magic-link--mobile-redirect-header {
  color: #454545;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 12px;
  line-height: 30px; }

.magic-link--mobile-redirect-msg {
  margin: 40px 0 8px; }

.magic-link--mobile-redirect-button {
  color: #fff;
  background-color: #d0207e;
  font-weight: bold;
  border: none; }
  .magic-link--mobile-redirect-button:hover {
    background-color: #d0207e; }

.magic-link-sent--icon {
  font-size: 30px;
  margin-bottom: 30px;
  color: #5598ab; }

.magic-link-sent--vol-envelope {
  width: 120px;
  height: 120px;
  margin: 24px auto; }

.magic-link-sent--btn {
  margin-top: 20px;
  font-size: 16px; }
  .magic-link-sent--btn.is-volunteers {
    background-color: #77d3d4;
    border-color: #77d3d4; }
    .magic-link-sent--btn.is-volunteers:hover:not(:disabled) {
      background-color: #8ad9da; }
    .magic-link-sent--btn.is-volunteers:focus:not(:disabled) {
      background-color: #77d3d4;
      border-color: #77d3d4; }

.mfa {
  margin: 0 auto;
  position: relative; }
  .mfa.is-evertrue, .mfa.is-super, .mfa.is-data {
    background-color: #fff;
    border-radius: 16px;
    border: 4px solid black;
    padding: 32px 48px 32px 48px; }

.mfa--header {
  height: 100px;
  position: relative; }
  .mfa--header.is-evertrue, .mfa--header.is-super, .mfa--header.is-data {
    font-weight: bold;
    font-size: 24px;
    text-align: center; }

.mfa--header-phone-image {
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 100px;
  width: 200px;
  margin-left: -100px; }
  .mfa--header-phone-image.is-evertrue, .mfa--header-phone-image.is-super, .mfa--header-phone-image.is-data {
    bottom: -40px; }

.mfa--heading {
  font-weight: 500;
  text-align: center;
  font-size: 20px;
  margin-bottom: 10px; }

.mfa--content {
  border-top: solid 1px #e5ebed;
  padding: 28px 30px;
  border-radius: 0 0 2px 2px; }
  .mfa--content.is-evertrue, .mfa--content.is-super, .mfa--content.is-data {
    margin-top: 40px;
    padding: 0px 30px; }

.mfa--note {
  text-align: center;
  margin-top: 16px;
  color: #454545; }

.mfa--create-subtext {
  text-align: left;
  margin-bottom: 16px;
  padding-top: 8px; }

.mfa--subtext {
  text-align: center;
  margin-bottom: 16px;
  padding-top: 8px; }

.mfa--text {
  font-size: 16px;
  font-weight: 500;
  margin: 12px 0; }

input.mfa--input {
  width: 100%;
  font-size: 18px;
  letter-spacing: 2px;
  font-weight: 500;
  margin-bottom: 12px;
  margin-top: 8px;
  padding: 8px; }
  input.mfa--input:focus {
    border: solid 1px #7ebd37; }
  input.mfa--input.is-error {
    border-color: #f8d0c8;
    margin-bottom: 2px 10px; }

.mfa--error-text {
  background: #f8d0c8;
  border-radius: 3px;
  padding: 8px; }

.mfa--trust-device {
  margin: 8px 0;
  display: inline-block;
  line-height: 1;
  vertical-align: middle; }

.mfa--button {
  width: 100%;
  font-size: 16px;
  margin-top: 10px; }
  .mfa--button:disabled, .mfa--button:disabled:hover {
    border-color: #d8d8d8;
    box-shadow: none;
    border-bottom-width: 1px;
    color: #a7a7a7;
    cursor: default; }

.mfa--resend-link-area {
  text-align: center;
  padding: 10px; }

.mfa--resend-status,
.mfa--resend-link {
  display: inline-block;
  padding: 6px 10px; }

.iti__flag {
  background-image: url("../../node_modules/intl-tel-input/build/img/flags.png"); }

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("../../node_modules/intl-tel-input/build/img/flags@2x.png"); } }

.intl-tel-input {
  position: relative;
  display: inline-block;
  width: 100%; }

.phone-input {
  padding-top: 8px; }

.org-picker {
  position: relative;
  height: 100%; }

.org-picker--wrapper .app-layout--body {
  padding: 16px 0 0 0;
  height: 100%; }

.org-picker--full-height {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1; }

.org-picker--header {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  padding-bottom: 16px; }

.org-picker--body {
  height: 100%;
  position: relative;
  border-top: solid 1px #e5ebed; }
  .org-picker--body .et--advanced-combobox--item {
    padding: 8px 16px; }

.org-picker--selector {
  height: 100%;
  position: relative; }
  .org-picker--selector .et--advanced-combobox-content {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1; }
  .org-picker--selector .et--advanced-combobox-content--list {
    height: 100%;
    position: relative;
    min-height: 200px; }
  .org-picker--selector .et--advanced-combobox-content--list-inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll; }

.org-picker--empty {
  padding: 32px;
  line-height: 21px;
  text-align: center;
  color: #757575; }

.user-picker {
  background-color: #fff;
  border-radius: 16px;
  border-style: solid;
  border: 4px solid black;
  overflow: hidden; }

.user-picker--title {
  padding: 12px 0 12px 0;
  margin: 0px;
  text-align: center; }

.user-picker--cards {
  min-height: 74px; }

.user-picker--user-card {
  padding: 12px;
  cursor: pointer;
  min-height: 64px;
  border-top: 2px solid #d8d8d8; }
  .user-picker--user-card:hover {
    background: #f7f7f7; }

.user-picker--user-avatar {
  display: inline-block;
  vertical-align: middle;
  margin-right: 16px;
  width: 40px; }

.user-picker--user-details {
  width: calc(100% - 66px);
  display: inline-block;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 21px; }

.error-message {
  display: flex;
  align-items: center;
  color: #eb534b;
  border: solid 1px #eb534b;
  background-color: #f8d0c8;
  margin-bottom: 24px;
  padding: 8px;
  border-radius: 2px; }

.error-message--icon {
  font-size: 18px;
  margin-right: 8px; }

.error-message--text {
  line-height: 20px; }

.error-message--link {
  margin-left: 4px; }

.success-message {
  display: flex;
  align-items: center;
  color: #468847;
  border: solid 1px #468847;
  background-color: #dff0d8;
  margin-bottom: 24px;
  padding: 8px;
  border-radius: 2px; }

.success-message--icon {
  font-size: 18px;
  margin-right: 8px; }

.success-message--text {
  line-height: 20px; }

.success-message--link {
  margin-left: 4px; }

.warning-message {
  display: flex;
  align-items: center;
  color: #c09853;
  border: solid 1px #c09853;
  background-color: #fcf8e3;
  margin-bottom: 24px;
  padding: 8px;
  border-radius: 2px; }

.warning-message--icon {
  font-size: 18px;
  margin-right: 8px; }

.warning-message--text {
  line-height: 20px; }

.warning-message--link {
  margin-left: 4px; }

.mobile-apps {
  text-align: center; }

.mobile-apps--title {
  color: #454545;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 12px;
  line-height: 30px; }

.mobile-apps--button {
  color: #fff;
  background-color: #d0207e;
  font-weight: bold;
  border: none; }
  .mobile-apps--button:hover {
    background-color: #d0207e; }

.mobile-apps--text {
  margin: 40px 0 8px; }

.mobile-apps--badge-wrapper {
  display: block;
  margin-top: 16px; }

.mobile-apps--badge {
  width: 180px;
  margin: 0 auto; }

.mobile-apps--image-wrapper {
  max-width: 440px;
  margin: 40px -32px -34px; }

.mobile-apps--image {
  width: 100%; }

.enter-email--body.is-super, .enter-email--body.is-data {
  background-color: #fff;
  border-radius: 16px;
  border: 4px solid black;
  padding: 32px 48px 32px 48px; }

.enter-email--body.is-evertrue {
  text-align: center; }

.enter-email--input {
  border: solid 1px #e5ebed;
  border-radius: 2px;
  padding: 8px 16px;
  height: 40px;
  font-family: "Roboto", "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif;
  font-size: 14px;
  display: block;
  width: 100%;
  margin-bottom: 8px; }
  .enter-email--input::placeholder {
    color: #a7a7a7; }
  .enter-email--input:focus {
    outline: none;
    box-shadow: none;
    border-color: #d6dfe3; }
  .enter-email--input:hover {
    border-color: #d6dfe3; }
  .enter-email--input.is-evertrue {
    padding: 8px;
    border-radius: 8px; }

.enter-email--org-slug-input {
  border: solid 1px #e5ebed;
  border-radius: 2px;
  padding: 8px 16px;
  height: 40px;
  font-family: "Roboto", "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif;
  font-size: 14px;
  display: block;
  width: 100%;
  margin-bottom: 8px; }
  .enter-email--org-slug-input::placeholder {
    color: #a7a7a7; }
  .enter-email--org-slug-input:focus {
    outline: none;
    box-shadow: none;
    border-color: #d6dfe3; }
  .enter-email--org-slug-input:hover {
    border-color: #d6dfe3; }

.enter-email--label {
  margin-bottom: 8px;
  margin-left: 8px;
  font-weight: bold; }
  .enter-email--label.is-evertrue {
    margin-top: 40px;
    margin-bottom: 20px;
    font-weight: normal; }

.enter-email--current-email {
  margin: 8px 0 16px 8px; }

.enter-email--org-slug-label {
  margin-bottom: 8px;
  margin-left: 8px;
  font-weight: bold; }

.enter-email--org-slug-help-text {
  padding-top: 16px;
  text-align: center; }

.enter-email--button.is-volunteers {
  margin-top: 16px;
  background-color: #77d3d4;
  border-color: #77d3d4; }
  .enter-email--button.is-volunteers:hover:not(:disabled) {
    background-color: #8ad9da; }
  .enter-email--button.is-volunteers:focus:not(:disabled) {
    background-color: #77d3d4;
    border-color: #77d3d4; }

.enter-email--button.is-evertrue, .enter-email--button.is-super, .enter-email--button.is-data {
  width: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  color: white;
  background-color: #30006c;
  border-color: #30006c;
  border-radius: 8px;
  box-shadow: none; }
  .enter-email--button.is-evertrue:hover:not(:disabled), .enter-email--button.is-super:hover:not(:disabled), .enter-email--button.is-data:hover:not(:disabled) {
    background-color: #3b0086; }
  .enter-email--button.is-evertrue:focus:not(:disabled), .enter-email--button.is-super:focus:not(:disabled), .enter-email--button.is-data:focus:not(:disabled) {
    background-color: #30006c;
    border-color: #30006c; }

.enter-email--button.is-evertrue {
  margin-top: 40px; }

.enter-email--button-li {
  border: none;
  color: #fff;
  margin-top: 16px;
  background-color: #0077b5; }
  .enter-email--button-li:hover {
    background-color: #0088cf; }

.enter-email--mobile {
  text-align: center; }

.enter-email--mobile-text {
  margin: 16px 0 18px;
  font-size: 125%;
  font-weight: bold; }

.enter-email--badges {
  display: flex;
  justify-content: space-evenly; }
  @media only screen and (max-width: 500px) {
    .enter-email--badges {
      flex-direction: column; } }

.enter-email--badge {
  width: 180px; }

.enter-email--community {
  margin-top: 36px;
  text-align: center;
  line-height: 21px; }

.enter-email--community-link {
  display: block; }

.enter-email--error {
  color: #eb534b; }

.enter-email--evertrue-help-text {
  margin-top: 40px;
  text-align: center; }

.marketing-banner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  color: #fff;
  background-color: #73c169;
  padding: 12px 0; }
  @media only screen and (max-width: 500px) {
    .marketing-banner {
      bottom: 0;
      top: auto; } }
  .marketing-banner a {
    margin-left: 4px;
    color: #fff;
    text-decoration: underline; }

.status-indicator {
  display: inline; }

a.status-indicator--link {
  color: #757575; }
  a.status-indicator--link:hover {
    text-decoration: underline;
    color: #757575; }

.status-indicator--dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 12px 0 8px;
  border-radius: 99px; }
  .status-indicator--dot.critical {
    background-color: #e74c3c; }
  .status-indicator--dot.major {
    background-color: #e74c3c; }
  .status-indicator--dot.minor {
    background-color: #f1c40f; }
  .status-indicator--dot.none {
    background-color: #2ecc71; }

.register {
  width: 100%;
  margin: 0 auto;
  position: relative;
  text-align: center; }

.register--wrapper {
  background-color: #fff;
  border-radius: 16px;
  border: 4px solid black;
  padding: 32px 48px 32px 48px; }
  .register--wrapper .register--header {
    font-weight: 600; }

.register--subheader {
  padding-bottom: 16px; }

.register--email {
  padding-bottom: 16px; }

.register--linkedin-btn {
  background-color: #0077b5;
  border: none;
  color: #fff; }
  .register--linkedin-btn:hover {
    background-color: #0088cf; }
  .register--linkedin-btn i {
    margin-right: 8px; }

.register--email-btn {
  background-color: #f7f7f7;
  border: solid 1px #dfe6e9;
  color: #454545;
  height: auto;
  padding: 12px 16px;
  border-bottom-width: 2px; }
  .register--email-btn:hover {
    border-color: #d8d8d8;
    box-shadow: 0 1px 2px #f7f7f7;
    background-color: #f7f7f7; }

.register--separator {
  position: relative;
  margin: 12px 0; }
  .register--separator::before {
    content: "";
    height: 1px;
    width: 100%;
    background-color: #e5ebed;
    position: absolute;
    top: 50%;
    right: 0%; }

.register--separator-text {
  background: #fff;
  padding: 0 16px;
  color: #a7a7a7;
  position: relative; }

.register--current-email {
  text-align: left;
  padding: 12px 0; }

.register--forgot {
  text-align: left;
  margin: 12px 0;
  display: block; }

.register--content {
  width: 320px;
  margin: 150px auto;
  text-align: center; }

.volunteer-register {
  text-align: center;
  font-size: 16px; }

.volunteer-register--header {
  font-size: 30px;
  line-height: 38px;
  letter-spacing: 0.2px;
  margin-bottom: 20px; }

.volunteer-register--text {
  font-size: 14px;
  margin: 10px 0 30px;
  line-height: normal; }

.confirmation--wrapper {
  background-color: #fff;
  border-radius: 16px;
  border: 4px solid black;
  padding: 32px 48px 32px 48px; }

.confirmation--title {
  text-align: center;
  color: #454545;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px; }

.confirmation--img {
  height: 128px;
  background-size: 128px;
  background-image: url("/images/confirmation_success.png");
  background-repeat: no-repeat;
  background-position: center; }

.confirmation--text {
  text-align: center;
  margin: 8px 0 16px; }
